// src/components/IntroductionCard.tsx
import React from "react";
import { Card, Text, Group, Avatar, Button, Stack, Flex } from "@mantine/core";
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconMail,
} from "@tabler/icons-react";
import { isMobile } from "react-device-detect";

interface IntroductionCardProps {
  imageUrl: string;
  name: string;
  title: string;
  githubUrl: string;
  linkedinUrl: string;
  email: string;
  address: string;
  description: string;
}

const IntroductionCard: React.FC<IntroductionCardProps> = ({
  imageUrl,
  name,
  title,
  githubUrl,
  linkedinUrl,
  email,
  address,
  description,
}) => {
  return isMobile ? (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Flex
        direction={isMobile ? "column" : "row"} // Stacks vertically on mobile, horizontal on larger screens
        align={isMobile ? "center" : "flex-start"}
        justify="center"
      >
        {/* Profile Image */}
        <Avatar
          src={imageUrl}
          size={isMobile ? 80 : 120}
          radius="md"
          mb={isMobile ? "md" : 0}
        />

        {/* Details Section */}
        <Stack
          gap={4}
          align={isMobile ? "center" : "flex-start"}
          style={{ width: "calc(100%)" }}
        >
          <Text size={isMobile ? "lg" : "xl"} w={700} ta={"center"}>
            {name}
          </Text>

          <Text size={isMobile ? "lg" : "lg"} c="dimmed" w={300} ta={"center"}>
            {title}
          </Text>

          {/* <Text size="sm" mt="xs" ta={isMobile ? "center" : "left"}>

          </Text> */}
          <div>{description}</div>

          {/* Contact Information */}
          <Stack gap={2} align={isMobile ? "center" : "flex-start"}>
            <Group gap="xs">
              <IconMail size={16} />
              <Text size="sm" ta={isMobile ? "center" : "left"}>
                {email}
              </Text>
            </Group>

            <Text size="sm" ta={isMobile ? "center" : "left"}>
              {address}
            </Text>
          </Stack>

          {/* Social Links */}
          <Group gap="xs" mt="md" ta={isMobile ? "center" : "left"}>
            <Button
              component="a"
              href={githubUrl}
              target="_blank"
              leftSection={<IconBrandGithub size={18} />}
              variant="outline"
              color="dark"
              size={isMobile ? "xs" : "sm"}
            >
              GitHub
            </Button>
            <Button
              component="a"
              href={linkedinUrl}
              target="_blank"
              leftSection={<IconBrandLinkedin size={18} />}
              variant="outline"
              color="blue"
              size={isMobile ? "xs" : "sm"}
            >
              LinkedIn
            </Button>
          </Group>
        </Stack>
      </Flex>
    </Card>
  ) : (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Group wrap="nowrap">
        {/* Profile Image */}
        <Avatar src={imageUrl} size={120} radius="md" />

        {/* Details Section */}
        <Stack gap={4}>
          <Text size="xl" w={700}>
            {name}
          </Text>

          <Text size="md" color="dimmed">
            {title}
          </Text>

          <Text size="sm" mt="xs" style={{ maxWidth: "60%", padding: "5px" }}>
            {description}
          </Text>

          {/* Contact Information */}
          <Stack gap={2}>
            <Group gap="xs">
              <IconMail size={16} />
              <Text size="sm">{email}</Text>
            </Group>

            <Text size="sm">{address}</Text>
          </Stack>

          {/* Social Links */}
          <Group gap="xs" mt="md">
            <Button
              component="a"
              href={githubUrl}
              target="_blank"
              leftSection={<IconBrandGithub size={18} />}
              variant="outline"
              color="dark"
            >
              GitHub
            </Button>
            <Button
              component="a"
              href={linkedinUrl}
              target="_blank"
              leftSection={<IconBrandLinkedin size={18} />}
              variant="outline"
              color="blue"
            >
              LinkedIn
            </Button>
          </Group>
        </Stack>
      </Group>
    </Card>
  );
};

export default IntroductionCard;
