// src/components/ExternalButtonLink.tsx
import React from "react";
import { Button } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";

interface ExternalButtonLinkProps {
  url: string;
  label: string;
}

const ExternalButtonLink: React.FC<ExternalButtonLinkProps> = ({
  url,
  label,
}) => {
  return (
    <Button
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      rightSection={<IconExternalLink size={18} />}
      style={{
        backgroundColor: "#1e88e5",
        color: "#fff",
        padding: "0.5rem 1.5rem",
        borderRadius: "8px",
        transition: "background-color 0.3s, box-shadow 0.3s",
        marginRight: "5px",
        "&:hover": {
          backgroundColor: "#1565c0",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      {label}
    </Button>
  );
};

export default ExternalButtonLink;
