// src/components/ProjectFeedbackForm.tsx
import React, { useState } from "react";
import {
  TextInput,
  Button,
  Textarea,
  Rating,
  Title,
  Group,
  Text,
} from "@mantine/core";
import { createProjectFeedback } from "../api/pocketbase";

import { hasLength, isInRange, isNotEmpty, useForm } from "@mantine/form";

interface ProjectFeedbackFormProps {
  projectId: string;
}

const ProjectFeedbackForm: React.FC<ProjectFeedbackFormProps> = ({
  projectId,
}) => {
  // const [userName, setUserName] = useState("");
  // const [rating, setRating] = useState(0);
  // const [comment, setComment] = useState("");

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      userName: "",
      comment: "",
      rating: 5,
    },
    validate: {
      userName: hasLength(
        { min: 2 },
        "User Name must be greater than 2 characters"
      ),
      comment: isNotEmpty("Enter a honest opinion"),
      rating: isInRange({ min: 0, max: 5 }, "Rating must be between 0 and 5"),
    },
  });

  const handleSubmit = async (values: any) => {
    await createProjectFeedback(
      projectId,
      values.userName,
      values.rating,
      values.comment
    );
    form.reset();
  };

  return (
    <div className="p-1">
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values);
        })}
      >
        <Title order={4}>Leave Feedback for the Project</Title>
        <TextInput
          label="Your Name"
          key={form.key("userName")}
          {...form.getInputProps("userName")}
        />
        <Group>
          <Text fw={500}>Rate the project</Text>
          <Rating key={form.key("rating")} {...form.getInputProps("rating")} />
        </Group>
        <Textarea
          label="Your Feedback"
          key={form.key("comment")}
          {...form.getInputProps("comment")}
        />
        <Button type="submit" mt={5} fullWidth>
          Submit Feedback
        </Button>
      </form>
    </div>
  );
};

export default ProjectFeedbackForm;
