import { blogs, curriculum, stations, projects, feedbacks, project_feedback, general_feedback } from "../data/data"
import axios from 'axios';
import PocketBase from "pocketbase";
import { Project, Project_Feedback } from "../data/interfaces";

export const getStations = async () => {
    return stations;
}

export const getCurriculum = async () => {
    return curriculum;
}


export const getFeedback = async () => {
    return feedbacks;
}

export const getBlogPosts = async () => {
    return blogs;
}

// src/api/pocketbaseFeedback.ts

// Create project feedback
export const createProjectFeedback = async (
    projectId: string,
    userName: string,
    rating: number,
    comment: string
) => {
    const record = await PB_Client_Instance.collection("project_feedbacks").create({
        project_id: projectId,
        user_name: userName,
        rating,
        comment,
        created_at: new Date()
    })
    return record;
};

export const getProjectFeedbacks = async () => {
    return await PB_Client().collection("project_feedbacks").getFullList();
}

// Get feedback for a specific project
export const getProjectFeedback = async (projectId: string) => {
    return project_feedback.filter(p => p.project_id == projectId);
    const records = await PB_Client_Instance.collection("project_feedback").getFullList({
        filter: `project_id = '${projectId}'`,
    });
    return records;
};

// Create general feedback
export const createGeneralFeedback = async (
    userName: string,
    email: string,
    comment: string
) => {
    const record = await PB_Client_Instance.collection("general_feedback").create({
        user_name: userName,
        email,
        comment,
        created_at: new Date(),
        confirmed: false, // Set to false until confirmed
    });
    return record;
};

// Get all general feedback
export const getGeneralFeedback = async () => {
    const records = await PB_Client_Instance.collection("general_feedback").getFullList({
        filter: "confirmed = true", // Only get confirmed feedback
    });
    return records;
};

// Confirm general feedback by email (simulating email confirmation)
export const confirmGeneralFeedback = async (id: string) => {
    const record = await PB_Client_Instance.collection("general_feedback").update(id, {
        confirmed: true,
    });
    return record;
};

let PB_Client_Instance: PocketBase;
export const PB_Client = () => {
    if (!PB_Client_Instance) {
        try {
            const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;
            PB_Client_Instance = new PocketBase(apiUrl);
            console.log(apiUrl)
            //PB_Client_Instance.collection('users').authWithPassword('users91484', 'aBRfLv5JWpc0yNo');
            PB_Client_Instance.autoCancellation(false);
        } catch (error) {
        }
    }
    return PB_Client_Instance;
}

export const executeRequest = (callback: any) => {
    try {
        callback()
    } catch (error) {
    }
}

export const getProjects = () => {
    return projects;
}

export const watchProjectFeedbacks = async () => {
    return await PB_Client().collection<Project_Feedback>('project_feedbacks');
}

export const unwatchProjectFeedbacks = async () => {
    return await PB_Client().collection<Project_Feedback>('project_feedbacks').subscribe("*", console.log);
}
