// src/components/ProjectFeedbackList.tsx
import React, { useEffect, useState } from "react";
import { Project_Feedback } from "../data/interfaces";
import { Group, Rating, Text, Title } from "@mantine/core";
import obscenity, {
  englishDataset,
  englishRecommendedTransformers,
  parseRawPattern,
  RegExpMatcher,
  TextCensor,
} from "obscenity";

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers,
});

const censor = new TextCensor();

const censorWords = (words: string) => {
  return censor.applyTo(words, matcher.getAllMatches(words));
};

var dateOptions: any = { year: "numeric", month: "long" };

interface ProjectFeedbackListProps {
  projectId: string;
  itemFeedbacks?: Project_Feedback[];
}

const ProjectFeedbackList: React.FC<ProjectFeedbackListProps> = ({
  projectId,
  itemFeedbacks,
}) => {
  // const [feedback, setFeedback] = useState<Project_Feedback[]>([]);
  const [rating, setRating] = useState(5);

  // useEffect(() => {
  //   const fetchFeedback = async () => {
  //     const feedbackList: any = await getProjectFeedback(projectId);
  //     setFeedback(feedbackList);
  //   };
  //   fetchFeedback();
  // }, [projectId]);

  return (
    <div className="p-2 border ">
      <Title order={4} className="static" py={5}>
        {itemFeedbacks?.length
          ? "Users gave the following comments for this project."
          : "No comments so far."}
      </Title>
      <Group className="max-h-[400px] overflow-auto">
        {itemFeedbacks?.map((item, index) => (
          <div key={index} className="border-b border-t p-1 w-full">
            <Group className="py-1" align="center">
              <Text>{censorWords(item.user_name)}</Text> (
              {new Date(item.created_at).toLocaleDateString(
                "de-DE",
                dateOptions
              )}
              ):
              <Rating value={item.rating} onChange={setRating} />
            </Group>
            <Text>{censorWords(item.comment)}</Text>
          </div>
        ))}
      </Group>
    </div>
  );
};

export default ProjectFeedbackList;
