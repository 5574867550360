// src/components/ProjectCard.js
import React, { useContext, useState } from "react";
import {
  Card,
  Text,
  Image,
  Rating,
  Textarea,
  Button,
  Group,
  Timeline,
  SimpleGrid,
  Badge,
  Table,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { Project, Project_Feedback } from "../data/interfaces";
import { skillIcons } from "./SkillIcon";

import { isMobile } from "react-device-detect";
import ProjectFeedbackForm from "./ProjectFeedbackForm";
import ProjectFeedbackList from "./ProjectFeedbackList";
import ExternalButtonLink from "./ExternalButtonLink";
// import { ProjectFeedbacksContext } from "../App";

const ProjectCard = ({
  project,
  feedbacks,
}: {
  project: Project;
  feedbacks: Project_Feedback[];
}) => {
  const [rating, setRating] = useState(project.rating || 0);
  const [showFeedbacks, setShowFeedbacks] = useState(false);

  // const { feedbacks } = useContext(ProjectFeedbacksContext);

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      {/* Image Slider */}
      <Carousel
        withIndicators
        height={isMobile ? 150 : 300}
        controlsOffset="md"
        loop
        slidesToScroll={1}
      >
        {project.images?.map((image, index) => (
          <Carousel.Slide key={index}>
            <Image
              src={image}
              style={{ height: isMobile ? 150 : 300, objectFit: "contain" }}
              alt={`Project Image ${index + 1}`}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      {project.projectUrl && (
        <Text>
          <ExternalButtonLink
            url={project.projectUrl}
            label={"Open in New Tab"}
          />
        </Text>
      )}
      {/* Project Description */}
      <Text size="xl" mt="md">
        {project.description}
      </Text>

      <Group className="py-2">
        {project.skills?.map((skill, i) => (
          <Badge
            key={i}
            size="lg"
            color="blue"
            variant="light"
            leftSection={skillIcons[skill] || null} // Display icon if available
          >
            {skill}
          </Badge>
        ))}
      </Group>

      {/* Rating System */}

      {/* <Group className="py-2" align="center">
        <Text>Project Self-Rating:</Text>
        <Rating readOnly value={rating} fractions={2} />
      </Group> */}

      <Table>
        <Table.Tr>
          <Table.Td>
            <Group className="py-2" align="center">
              <Text>Project Self-Rating:</Text>
              <Rating readOnly value={rating} fractions={2} />
            </Group>
          </Table.Td>
          <Table.Td>
            <Group className="py-2" align="center">
              <Text style={{ color: "orange", fontWeight: "bold" }}>
                {
                  feedbacks.filter(
                    (f) => f.project_id == project.id?.toString()
                  ).length
                }
              </Text>
              <Text>Comments</Text>
            </Group>
          </Table.Td>
        </Table.Tr>
      </Table>

      <Button onClick={() => setShowFeedbacks(!showFeedbacks)}>
        {showFeedbacks ? "Hide comments" : "Show comments"}
      </Button>
      {showFeedbacks && (
        <>
          <ProjectFeedbackForm projectId={project.id}></ProjectFeedbackForm>
          <ProjectFeedbackList
            projectId={project.id}
            itemFeedbacks={JSON.parse(
              JSON.stringify(
                feedbacks.filter((f) => f.project_id == project.id?.toString())
              )
            )}
          ></ProjectFeedbackList>
        </>
      )}
      {/* <Textarea
        label="Feedback"
        placeholder="Leave your feedback for this project..."
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        autosize
        className="py-1"
      />
      <Button fullWidth mt="sm">
        Submit Feedback
      </Button> */}
    </Card>
  );
};

export default ProjectCard;
