// src/components/ExternalLinksSection.tsx
import React from "react";
import {
  Stack,
  Title,
  Divider,
  Container,
  Group,
  List,
  Table,
  Card,
} from "@mantine/core";
import ExternalLink from "./ExternalLink";
import ExternalButtonLink from "./ExternalButtonLink";

const ExternalLinksSection: React.FC = () => {
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Title order={3}>Explore More Resources</Title>
      <Divider />

      <ExternalLink
        url="https://tran-nguyen.com/"
        label="Visit Tran Nguyen UG (haftungsbeschränkt)"
      />
      <Divider />
      <ExternalLink url="https://fun.tran-nguyen.com/" label="Fun Games" />
      <Divider />
      <ExternalLink
        url="https://cotuong-online.com"
        label="Chess, Schach, Cờ Tướng"
      />
      <Divider />
      <ExternalLink
        url="https://ants-world.baotran.de"
        label="Ants simulation"
      />
      <Divider />

      {/* <ExternalButtonLink
        url="https://cotuong-online.com"
        label="Chess, Schach, Cờ Tướng"
      />
      <ExternalButtonLink
        url="https://www.linkedin.com/in/baotran-de/"
        label="Connect on LinkedIn"
      /> */}
    </Card>
  );
};

export default ExternalLinksSection;
