// src/components/Stations.js
import React, { useEffect, useState } from "react";
import { Container, Timeline, Text, Title, Badge, Group } from "@mantine/core";
import { getStations } from "../api/pocketbase";
import { Station } from "../data/interfaces";
import { skillIcons } from "./SkillIcon";

var dateOptions: any = { year: "numeric", month: "long" };

const Stations = () => {
  const [stations, setStations] = useState<Station[]>([]);

  useEffect(() => {
    async function fetchStations() {
      const data: any = await getStations();
      setStations(data);
    }
    fetchStations();
  }, []);

  return (
    <Container size="xl" mt="xl">
      <Title order={2}>Work Experience</Title>
      <Timeline active={stations.length} mt="md">
        {stations.map((station, index) => (
          <Timeline.Item key={station.id} title={station.role}>
            <Text size="md" color="dimmed">
              {new Date(station.start_date).toLocaleDateString(
                "de-DE",
                dateOptions
              )}{" "}
              -{" "}
              {station.end_date
                ? new Date(station.end_date).toLocaleDateString(
                    "de-DE",
                    dateOptions
                  )
                : "Present"}
            </Text>
            <Text size="md" mt="xs">
              {station.description}
            </Text>
            <Group className="py-2">
              {station.skills?.map((skill, i) => (
                <Badge
                  key={i}
                  size="lg"
                  color="blue"
                  variant="light"
                  leftSection={skillIcons[skill] || null} // Display icon if available
                >
                  {skill}
                </Badge>
              ))}
            </Group>
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
};

export default Stations;
