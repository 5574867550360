import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import {
  AppShellMain,
  Button,
  ComboboxData,
  Container,
  Flex,
  Grid,
  Group,
  MantineProvider,
  Menu,
  Popover,
  Select,
  SimpleGrid,
  Table,
} from "@mantine/core";
import HeroSection from "./components/HeroSection";
import ProjectsList from "./components/ProjectsList";
import Curriculum from "./components/Curriculum";
import Stations from "./components/Stations";
import Feedback from "./components/Feedback";
import Blog from "./components/Blog";
import ScrollToTop from "./components/ScrollTop";
import ThemeToggle from "./components/ThemeToggle";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import { AppShell, Burger } from "@mantine/core";
import { Game, Project_Feedback } from "./data/interfaces";

import { IconCheck } from "@tabler/icons-react";
import ExternalLinksSection from "./components/ExternalLinkSection";
import BlogSidebar from "./components/BlogPostsSidebar";
import IntroductionCard from "./components/IntroductionCard";
import { introduction } from "./data/data";
import { unwatchProjectFeedbacks } from "./api/pocketbase";
import { isMobile } from "react-device-detect";

const defaultGames: Game[] = [
  {
    id: "tetris",
    label: "Tetris",
    value: "tetris",
  },
  {
    id: "designer",
    label: "Designer",
    value: "designer",
  },
  {
    id: "rpgGame",
    label: "RPG Game",
    value: "rpgGame",
  },
];

// export type ProjectFeedbacksContextType = {
//   feedbacks: Project_Feedback[];
//   setFeedbacks: Dispatch<SetStateAction<Project_Feedback[]>>;
// };

// export const ProjectFeedbacksContext =
//   React.createContext<ProjectFeedbacksContextType>({
//     feedbacks: [],
//     setFeedbacks: () => {},
//   });

function App() {
  const projectRef = useRef<HTMLDivElement>(null);
  const [games, setGames] = useState<Game[]>(defaultGames);
  const [selectedGameId, setSelectedGameId] = useState<string>("tetris");

  const [showGame, setShowGame] = useState(false);
  const [showBlogs, setShowBlogs] = useState(false);

  const [feedbacks, setFeedbacks] = useState<Project_Feedback[]>([]);

  return (
    <MantineProvider>
      <AppShell>
        <AppShell.Main>
          <ThemeToggle />
          <HeroSection projectRef={projectRef} />
          <Container size={"xl"}>
            <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 2 }}>
              <IntroductionCard {...introduction}></IntroductionCard>
              <ExternalLinksSection></ExternalLinksSection>
            </SimpleGrid>
          </Container>
          <Curriculum />
          <Stations />
          {/* <ProjectFeedbacksContext.Provider value={{ feedbacks, setFeedbacks }}> */}
          <div ref={projectRef}>
            <ProjectsList feedbacks={feedbacks} setFeedbacks={setFeedbacks} />
          </div>
          {/* </ProjectFeedbacksContext.Provider> */}
          <Feedback />
          {/* <Blog /> */}
          {/* <BlogSidebar></BlogSidebar> */}
          <ScrollToTop />
        </AppShell.Main>
        {/* <AppShell.Aside
          style={{
            visibility: showBlogs ? "visible" : "hidden",
            width: "40vw",
          }}
        >
          <BlogSidebar></BlogSidebar>
        </AppShell.Aside> */}
        <AppShell.Footer
          style={{
            width: "90%",
            left: "5%",
            outline: "1px solid rgb(34, 139, 230)",
            borderRadius: "10px",
          }}
        >
          <div
            className="flex justify-center items-center"
            style={{
              visibility: showGame || showBlogs ? "visible" : "hidden",
              height: showGame ? "95vh" : "0px",
              maxHeight: isMobile ? "800px" : "1000px",
            }}
          >
            <iframe
              src="https://fun.tran-nguyen.com"
              className="h-full w-[100%] z-10 p-1"
              style={{ visibility: showGame ? "visible" : "hidden" }}
            ></iframe>
          </div>

          <div className="flex justify-center items-center h-full">
            <Button
              onClick={() => setShowGame(!showGame)}
              style={{ fontSize: 20, fontWeight: "bold", padding: "5px 20px" }}
            >
              {showGame ? "Hide Games" : "Show games"}
            </Button>
            {/* <Button
              onClick={() => setShowBlogs(!showBlogs)}
              style={{ fontSize: 20, fontWeight: "bold", padding: "5px 20px" }}
            >
              {showBlogs ? "Hide Blogs" : "Show blogs"}
            </Button> */}
          </div>
        </AppShell.Footer>
      </AppShell>
    </MantineProvider>
  );
}

export default App;

// <Menu shadow="md">
// <Menu.Target>
//   <Button w={500}>Toggle menu</Button>
// </Menu.Target>

// <Menu.Dropdown style={{ backgroundColor: "#1c7ed6" }} w={500}>
//   {games &&
//     games.map((item) => {
//       return (
//         <Menu.Item
//           className="hover:bg-gray-50"
//           onClick={() => setSelectedGameId(item.value)}
//           style={{
//             textAlign: "center",
//             fontWeight: "bold",
//             color: "black",
//           }}
//         >
//           <div className="flex items-center justify-center">
//             <strong className="p-1 pr-2">{item.label}</strong>
//             {selectedGameId == item.value && (
//               <IconCheck></IconCheck>
//             )}
//           </div>
//         </Menu.Item>
//       );
//     })}
// </Menu.Dropdown>
// </Menu>
