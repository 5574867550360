// src/components/Feedback.js
import React, { useEffect, useState } from "react";
import { Container, Title, Card, Text, SimpleGrid } from "@mantine/core";
import { getFeedback } from "../api/pocketbase";
import { Feedback } from "../data/interfaces";

const FeedbackComp = () => {
  const [myComments, setMyComments] = useState<Feedback[]>([]);

  useEffect(() => {
    async function fetchFeedback() {
      const data: any = await getFeedback();
      setMyComments(data);
    }
    fetchFeedback();
  }, []);

  return (
    <Container size="xl" my="xl">
      <Title order={2}>Testimonials</Title>
      <SimpleGrid cols={2} spacing="lg" mt="md">
        {myComments.map((item) => (
          <Card shadow="sm" padding="lg" key={item.id}>
            <Text>
              {item.name} - {item.role}
            </Text>
            <Text size="md" color="dimmed" mt="sm">
              "{item.feedback}"
            </Text>
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default FeedbackComp;
