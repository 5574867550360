// src/components/HeroSection.js
import React, { Ref, forwardRef, RefObject } from "react";
import { Button, Container, Text, Title } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";

const HeroSection = forwardRef(
  ({ projectRef }: { projectRef: RefObject<HTMLElement> }) => {
    const scrollIntoProject: React.MouseEventHandler<HTMLElement> = (event) => {
      event.preventDefault();
      projectRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <Container size="xl" style={{ textAlign: "center", padding: "50px 0" }}>
        <Title order={1} style={{ fontSize: "3rem", fontWeight: "bold" }}>
          Welcome to{" "}
          <span style={{ color: "#1c7ed6" }}>My Personal Website</span>
        </Title>
        <Text size="xl" mt="md" color="dimmed">
          Discover my projects, skills, and more!
        </Text>
        <Button
          variant="filled"
          color="blue"
          size="lg"
          mt="xl"
          onClick={scrollIntoProject}
        >
          View My Projects
        </Button>

        {/* This is the target for scrolling
      <div ref={targetRef} /> */}
      </Container>
    );
  }
);

export default HeroSection;
