// src/components/Curriculum.js
import React, { useEffect, useState } from "react";
import { Container, Title, List, Text, Group, Badge } from "@mantine/core";
import { getCurriculum } from "../api/pocketbase";
import { Curriculum } from "../data/interfaces";
import { skillIcons } from "./SkillIcon";

const CurriculumComp = () => {
  const [curriculum, setCurriculum] = useState<Curriculum[]>([]);

  useEffect(() => {
    async function fetchCurriculum() {
      const data: any = await getCurriculum();
      setCurriculum(data);
    }
    fetchCurriculum();
  }, []);

  return (
    <Container size="xl" mt="xl">
      <Title order={2}>Curriculum Vitae</Title>
      <List spacing="sm" size="lg" mt="md">
        {curriculum.map((item) => (
          <List.Item key={item.id}>
            <Text style={{ fontWeight: 500 }}>
              {item.title} - {item.years} years
            </Text>
            <Text size="md" color="dimmed">
              {item.description}
            </Text>
            <Group className="py-2">
              {item.skills?.map((skill, i) => (
                <Badge
                  key={i}
                  size="lg"
                  color="blue"
                  variant="light"
                  leftSection={skillIcons[skill] || null} // Display icon if available
                >
                  {skill}
                </Badge>
              ))}
            </Group>
          </List.Item>
        ))}
      </List>
    </Container>
  );
};

export default CurriculumComp;
