export const introduction: any = {
  name: "Bao Tran",
  imageUrl: "baotran_profile_image.jpg",
  githubUrl: "https://github.com/pseudo13",
  linkedinUrl: "https://www.linkedin.com/in/baotran-de/",
  address: "Munich, Bavaria, Germany",
  email: "info@baotran.de",
  website: "http://www.baotran.de",
  title: "Senior Software Developer / Looking for interesting projects",
  description:
    "Hi, people. I'm a hard-worker and passionate about programming. I have experiences as fullstack developer. I like creating websites and web applications. Feel free to leave a message so we can be in touch!",

};


export const curriculum = [
  {
    "id": 1,
    "title": "Senior Software Developer",
    "description": "Proficient in basic technologies like Javascript, HTML, and CSS, SQL and in Frontend Frameworks like Angular, React, Vue or Blazor and also in Backend Frameworks like NodeJs, C# Web Api or Java Springboot." +
      " Expertise in building dynamic, responsive web applications with 10 years of experience.",
    "years": 10,
    "skills": ["JavaScript", "React", "Angular", "Vue", "Blazor", "Three.Js", "C# Web Api", "Spring Boot", "Docker"]
  },
  {
    "id": 2,
    "title": "Bachelor's Degree in Applied computer science",
    "description": "Graduated with a Bachelor's degree in Computer Science from Applied University Amberg & Weiden.",
    "years": 4.5,
    "skills": ["Software engineering", "Web programming", "Expertise in applied computer science"]
  }
];

export const stations = [
  {
    "id": 1,
    "company": "Open Text Software GmbH",
    "role": "Senior Software Developer",
    "start_date": "2021-08-01",
    "end_date": null,
    "images": ["images/antsworld.PNG", "images/cotuong-online.PNG"],
    "rating": 4.5,
    "skills": ["Angular", "TypeScript", "Spring Boot", "Apache Camel", "Docker", "Kubernetes"],
    "description": "Design and development of customer communication management platform. Focused on building responsive web application and optimizing dynamic UI components and workflow."
  },
  {
    "id": 2,
    "company": "INSCOPUS GmbH",
    "role": "IT-Consultant / Teamlead",
    "start_date": "2017-05-01",
    "end_date": "2020-12-31",
    "images": ["images/discountoo.PNG"],
    "rating": 4.5,
    "skills": ["Typescript", "Angular", "C# Web Api", "Ionic", "Kendo UI"],
    "description": "Led the front-end development for various web applications, using Angular and Ionic. Design and development of customization framework for collecting, managing and evaluating of project and company data"
  },
  {
    "id": 3,
    "company": "AVL Germany Ltd",
    "role": "Software Developer",
    "start_date": "2016-03-01",
    "end_date": "2017-04-31",
    "rating": 3,
    "skills": ["C#", "React", "Python Morepath", "Javascript"],
    "description": "Development of Test Information Management Software. Development and integrating of components in Morepath framework and Reactjs"
  },
  {
    "id": 4,
    "company": "Truma device technology Ltd",
    "role": "Software Developer",
    "start_date": "2014-03-01",
    "end_date": "2016-02-31",
    "rating": 3,
    "skills": ["C#", "Vb.Net", "LIN-Bussystem", "Winform"],
    "description": "Development of diagnosis software for heaters and air conditioners in Winform and Sqlite using LIN-Bussystem"
  }
];

export const projects = [
  {
    "id": 1,
    "title": "Personal website",
    "projectUrl": null,
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2019-06-01",
    "end_date": "2019-08-01",
    "images": ["projects/baotran.de.png"],
    "rating": 2.5,
    "skills": ["React", "Javascript", "MongoDb"],
    "description": "A website created with React to give visitors an overview about me, Bao Tran, a passionate developer from Munich, Germany"
  },
  {
    "id": 2,
    "title": "Discountoo",
    "projectUrl": "https://discountoo.tran-consulting.com/",
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2020-09-01",
    "end_date": "2020-11-01",
    "images": ["projects/discountoo_1.png", "projects/discountoo_2.png", "projects/discountoo_3.png"],
    "rating": 3,
    "skills": ["React", "Javascript", "Ant Design", "NodeJs", "Mongo Db"],
    "description": "An ecommerce website to sell products, built with React, Ant Design, NodeJs and MongoDb"
  },
  {
    "id": 3,
    "title": "Chess, Schach, Cờ Tướng",
    "projectUrl": "https://cotuong-online.com/",
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2021-01-01",
    "end_date": "2021-06-01",
    "images": ["projects/cotuong_1.png", "projects/cotuong_2.png", "projects/cotuong_3.png"],
    "rating": 3.5,
    "skills": ["React", "Javascript", "MongoDb"],
    "description": "A chess platform with multiple types of chess, with real-time communication which allows self-play, play versus opponents, built with Angular, NestJs and Socket.Io"
  },
  {
    "id": 4,
    "title": "Ants simulation",
    "projectUrl": "https://ants-world.baotran.de/",
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2022-10-01",
    "end_date": "2023-01-01",
    "images": ["projects/ant_world_1.png", "projects/ant_world_2.png"],
    "rating": 3.5,
    "skills": ["Angular", "Typescript", "SignalR", "Three.Js"],
    "description": "A project to learn more about 2D, 3D ants simulation using Angular, Three.Js and C# SignalR for realtime communication."
  },
  {
    "id": 5,
    "title": "Fun Games",
    "projectUrl": "https://fun.tran-nguyen.com/",
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2023-10-01",
    "end_date": "2024-06-01",
    "images": ["projects/fun_game_1.png", "projects/fun_game_2.png", "projects/fun_game_3.png", "projects/fun_game_4.png", "projects/fun_game_5.png"],
    "rating": 3.5,
    "skills": ["Angular", "Typescript", "Three.Js"],
    "description": "A project to learn more about Games using Angular and Three.Js"
  },
  {
    "id": 6,
    "title": "Future projects",
    "projectUrl": null,
    "company": "Private",
    "role": "Senior Software Developer",
    "start_date": "2024-10-01",
    "end_date": null,
    "images": ["projects/Future_Project.webp"],
    "rating": 5,
    "skills": ["Angular", "React", "Typescript", "Three.Js", "NodeJs", "Realtime communication", "Cloud computing", "Natural Language Processing", "Data Science", "More ..."],
    "description": "Tell me about your ideas so we can realize the projects together"
  },
];

export const feedbacks = [
  {
    "id": 1,
    "name": "DR",
    "role": "Project Manager in 2024",
    "feedback": "The dedication and attention to detail shown by Bao Tran was outstanding. He always deliver top-notch work on time."
  },
  {
    "id": 2,
    "name": "MW",
    "role": "CEO in 2020",
    "feedback": "Their ability to solve complex problems and take ownership of tasks made them a crucial member of the team. Highly recommend!"
  },
  {
    "id": 3,
    "name": "CL",
    "role": "CTO in 2020",
    "feedback": "An exceptional front-end developer with a deep understanding of Angular and modern JavaScript frameworks. Their leadership has been a key to our success."
  }
];

export const blogs = [
  {
    "id": 1,
    "title": "10 Tips to Improve Your React Apps",
    "content": "React is a powerful library for building dynamic UI, but it’s essential to optimize performance...",
    "date": "2024-01-05"
  },
  {
    "id": 2,
    "title": "Why TypeScript is a Game Changer",
    "content": "Switching to TypeScript can dramatically improve the scalability and maintainability of your codebase...",
    "date": "2023-11-20"
  },
  {
    "id": 3,
    "title": "A Guide to Responsive Web Design",
    "content": "Responsive design ensures that your web app looks great on any device. Here are the key strategies...",
    "date": "2023-10-10"
  }
];

export const project_feedback = [
  {
    "project_id": "1",
    "user_name": "Alice Johnson",
    "rating": 5,
    "comment": "This project is amazing! The UI is sleek and it's very fast.",
    "created_at": "2024-09-23T14:30:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Bob Smith",
    "rating": 4,
    "comment": "Great work! Some minor issues with responsiveness, but overall very good.",
    "created_at": "2024-09-22T11:15:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Jane Doe",
    "rating": 3,
    "comment": "The project is decent, but could use better documentation.",
    "created_at": "2024-09-21T09:45:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Alice Johnson",
    "rating": 5,
    "comment": "This project is amazing! The UI is sleek and it's very fast.",
    "created_at": "2024-09-23T14:30:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Bob Smith",
    "rating": 4,
    "comment": "Great work! Some minor issues with responsiveness, but overall very good.",
    "created_at": "2024-09-22T11:15:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Jane Doe",
    "rating": 3,
    "comment": "The project is decent, but could use better documentation.",
    "created_at": "2024-09-21T09:45:00Z"
  },
  {
    "project_id": "1",
    "user_name": "Alice Johnson",
    "rating": 5,
    "comment": "This project is amazing! The UI is sleek and it's very fast.",
    "created_at": "2024-09-23T14:30:00Z"
  },
  {
    "project_id": "2",
    "user_name": "Bob Smith",
    "rating": 4,
    "comment": "Great work! Some minor issues with responsiveness, but overall very good.",
    "created_at": "2024-09-22T11:15:00Z"
  },
  {
    "project_id": "2",
    "user_name": "Jane Doe",
    "rating": 3,
    "comment": "The project is decent, but could use better documentation.",
    "created_at": "2024-09-21T09:45:00Z"
  }
];

export const general_feedback = [
  {
    "user_name": "Charlie Brown",
    "email": "charlie@example.com",
    "comment": "I love the design of this website. It's very easy to navigate.",
    "created_at": "2024-09-20T10:30:00Z",
    "confirmed": true
  },
  {
    "user_name": "Lucy van Pelt",
    "email": "lucy@example.com",
    "comment": "Great work on the portfolio! The project descriptions are clear and concise.",
    "created_at": "2024-09-22T08:20:00Z",
    "confirmed": true
  },
  {
    "user_name": "Linus van Pelt",
    "email": "linus@example.com",
    "comment": "I had trouble viewing the site on mobile. Can you improve the mobile version?",
    "created_at": "2024-09-19T07:15:00Z",
    "confirmed": false
  }
];

