// src/components/ProjectsList.js
import React, {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Container, Timeline, Title, Text } from "@mantine/core";
import ProjectCard from "./ProjectCard";
import {
  watchProjectFeedbacks,
  getProjects,
  getProjectFeedbacks,
  unwatchProjectFeedbacks,
} from "../api/pocketbase";
import { Feedback, Project, Project_Feedback } from "../data/interfaces";
import { RecordSubscription, UnsubscribeFunc } from "pocketbase";

var dateOptions: any = { year: "numeric", month: "long" };

const ProjectsList = ({
  feedbacks,
  setFeedbacks,
}: {
  feedbacks: Project_Feedback[];
  setFeedbacks: Dispatch<SetStateAction<Project_Feedback[]>>;
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [unsubFunc, setUnsubFunc] = useState<UnsubscribeFunc>();
  const [editEntry, setEditEntry] =
    useState<RecordSubscription<Project_Feedback>>();
  const editRecordHandler = () => {
    if (!editEntry) return;
    const { action, record } = editEntry!;
    console.log(feedbacks);
    console.log(record);

    console.log("live query", action, record, feedbacks);
    if (action == "create") {
      if (!feedbacks.find((f) => f.id == record.id)) {
        setFeedbacks([...feedbacks, record]);
      }
    } else if (action == "delete") {
      setFeedbacks((prev) => prev.filter((f) => f.id != record.id));
    }
  };

  const init = async () => {
    const visibleProjects = getProjects() as any[];
    setProjects(visibleProjects.reverse());
    const items: any = await getProjectFeedbacks();
    setFeedbacks(items);

    (await watchProjectFeedbacks()).subscribe("*", (e) => {
      setEditEntry(e);
    });
  };

  useEffect(() => {
    editRecordHandler();
  }, [editEntry]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    return () => {
      unwatchProjectFeedbacks();
    };
  }, []);

  return (
    <Container size="xl" mt="xl">
      <Title order={2} mb="lg">
        My Projects
      </Title>
      <Timeline active={projects.length} bulletSize={24} lineWidth={2}>
        {projects.map((project) => (
          <Timeline.Item key={project.id} title={project.title} color="blue">
            <Text size="md" color="dimmed">
              {new Date(project.start_date).toLocaleDateString(
                "de-DE",
                dateOptions
              )}{" "}
              -{" "}
              {project.end_date
                ? new Date(project.end_date).toLocaleDateString(
                    "de-DE",
                    dateOptions
                  )
                : "Present"}
            </Text>
            <ProjectCard project={project} feedbacks={feedbacks} />
          </Timeline.Item>
        ))}
      </Timeline>
    </Container>
  );
};

export default ProjectsList;
