// src/components/ExternalLink.tsx
import React from "react";
import { Anchor, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";

interface ExternalLinkProps {
  url: string;
  label: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ url, label }) => {
  return (
    <Anchor
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.3rem",
        color: "#1e88e5",
        fontWeight: "bold",
        textDecoration: "none",
        padding: "0.5rem 1rem",
        borderRadius: "8px",
        transition: "background-color 0.3s ease, transform 0.3s ease",
      }}
      onMouseEnter={(e) => {
        (e.currentTarget as HTMLElement).style.backgroundColor = "#f0f4ff";
        (e.currentTarget as HTMLElement).style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        (e.currentTarget as HTMLElement).style.backgroundColor = "transparent";
        (e.currentTarget as HTMLElement).style.transform = "scale(1)";
      }}
    >
      <Text>{label}</Text>
      <IconExternalLink size={16} />
    </Anchor>
  );
};

export default ExternalLink;
